<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation', 'status']"
            ref="list"
        >
            <template slot="left_btn">
                <a-button
                    class="float_left"
                    v-auth="'change'"
                    @click="add()"
                    type="primary"
                    >添加分类</a-button
                >
            </template>
            <template slot="status" slot-scope="data">
                <a-tag color="#108ee9">
                    <span
                        v-for="item in status_list"
                        :key="item.key"
                        v-show="item.key == data.text"
                        >{{ item.value }}</span
                    >
                </a-tag>
            </template>

            <template slot="operation" slot-scope="data">
                <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm
                    title="您确定要删除吗?"
                    v-auth="'delete'"
                    @confirm="del(data.record)"
                >
                    <a>删除</a>
                </a-popconfirm>
            </template>
        </TableList>

        <EditPop
            @handleSubmit="submit"
            :form_data="form_data"
            :visible.sync="visible"
        ></EditPop>
    </div>
</template>

<script>
import EditPop from "@/components/EditPop";
import TableList from "@/components/TableList";
import { getCateList, changeCate, getCate, delCate } from "@/api/help";

const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "排序",
        dataIndex: "sort",
    },
    {
        title: "帮助分类",
        dataIndex: "title",
    },
    {
        title: "操作",
        width: 150,
        scopedSlots: {
            customRender: "operation"
        }
    },
];


export default {
    name: "Index",
    components: {
        EditPop,
        TableList
    },
    data() {
        return {
            get_table_list: getCateList,
            columns,
            visible: false,
            form_data: {
                title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: 'text',
                        name: 'title',
                        title: '帮助分类名称',
                        options: {},
                    },
                    {
                        type: 'text',
                        name: 'sort',
                        title: '排序',
                        options: {},
                    },
                ]
            }
        };
    },
    async created() {

    },
    methods: {
        add() {
            this.form_data.title = "添加"
            this.visible = true;
            this.form_data.list.forEach(item => {
                item.options.initialValue = "";
            })
        },
        edit(record) {
            this.form_data.title = "编辑"
            getCate({
                data: {
                    id: record.id
                }
            }).then(res => {
                let data = res.data
                this.form_data.list.forEach(item => {
                    item.options.initialValue = data[item.name];
                })
                this.visible = true;
            })
        },
        del(record) {
            delCate({
                data: {
                    id: record.id
                },
                info: true
            }).then(res => {
                this.$refs.list.get_list();
            })
        },

        submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
            changeCate({
                data: {
                    ...data
                },
                info: true
            }).then(res => {
                this.visible = false;
                this.$refs.list.get_list();
            })

        },

    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>